import styled from 'styled-components';

import { Section, Title } from '@/components/core';

export const StyledSection = styled(Section).attrs({
  forwardedAs: 'div',
})`
  position: relative;
  overflow: hidden;
  z-index: 1;

  @media (min-width: ${(props) => props.theme.breakpoints.min_md}px) {
    &:before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      width: 436px;
      height: 728px;
      background: url('/assets/shapes/careers_ellipse_ring_left.svg') no-repeat center center;
      background-size: contain;
      z-index: -1;
    }

    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 436px;
      height: 536px;
      background: url('/assets/shapes/careers_ellipse_right.svg') no-repeat center center;
      background-size: contain;
      z-index: -1;
    }
  }
`;

export const StyledTitle = styled(Title).attrs({
  forwardedAs: 'h3',
})`
  padding-bottom: 40px;
  padding-top: 16px;

  @media (min-width: ${(props) => props.theme.breakpoints.min_md}px) {
    padding-bottom: 64px;
    padding-top: 24px;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.min_lg}px) {
    padding-bottom: 80px;
    font-size: 55px;
    line-height: 64px;
  }
`;

export const StyledGrid = styled.ul`
  display: grid;
  grid-auto-rows: 1fr;
  row-gap: 24px;

  @media (min-width: ${(props) => props.theme.breakpoints.min_md}px) {
    grid-template-columns: 1fr 1fr;
    column-gap: 24px;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.min_lg}px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
`;

export const StyledFooter = styled.footer`
  padding-top: 64px;

  @media (min-width: ${(props) => props.theme.breakpoints.min_lg}px) {
    padding-top: 80px;
  }
`;
