import Section from '@/components/core/Section/Section';
import styled from 'styled-components';

export const Header = styled(Section)`
  display: grid;
  gap: 24px;
  justify-items: start;

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    gap: 16px;
  }
`;
