import { StyledContainer, StyledSection } from './styles';

const PostingContainer = ({ children, ...rest }) => {
  return (
    <StyledSection innerSpacer>
      <StyledContainer {...rest}>{children}</StyledContainer>
    </StyledSection>
  );
};

export default PostingContainer;
