import styled from 'styled-components';
import { UpperText } from '@/components/core';

export const StyledBadge = styled(UpperText)`
  padding: 8px 16px;
  background-color: ${(props) => props.theme.colors.neutral.dark};
  color: ${(props) => props.theme.colors.grey.default};
  display: inline-block;
  border-radius: 1000px;
  letter-spacing: 2px;
  opacity: 1;

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    padding: 4px 12px;
  }
`;
