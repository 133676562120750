import styled from 'styled-components';

export const Icon = styled.span`
  display: inline-flex;
  background-color: ${(props) => props.$bgColor || props.theme.colors.neutral.default};
  border-radius: 100%;
  width: 56px;
  height: 56px;
  align-items: center;
  justify-content: center;
  transition: all 120ms ease-in-out;

  svg {
    fill: ${(props) => props.$color || 'currentColor'};
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    background-color: transparent;
    width: 20px;
    height: 20px;
  }

  a:hover & {
    color: ${(props) => props.theme.colors.white};
    transform: translateX(8px);

    @media (min-width: ${(props) => props.theme.breakpoints.min_lg}px) {
      background-color: ${(props) => props.theme.colors.white}33;
    }
  }
`;
