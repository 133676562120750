import { StyledContent } from './styles';

const PostingContent = ({ children, minimal, ...rest }) => {
  return (
    <StyledContent {...rest} $minimal={minimal} highlightLinks>
      {children}
    </StyledContent>
  );
};

export default PostingContent;
