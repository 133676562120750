import axios from 'axios';

export default axios.create({
  baseURL: 'https://api.lever.co/v1',
  timeout: 10000,
  auth: {
    username: process.env.LEVER_API_KEY,
    password: '',
  },
});
