import { StyledCategories } from './styles';

const PostingCategories = ({ posting, ...rest }) => {
  if (!posting) return null;

  return (
    <StyledCategories {...rest}>
      {[
        posting.workplaceType,
        posting.categories.commitment,
        posting.categories.allLocations.join('/'),
      ]
        .filter(Boolean)
        .map((category) => (
          <span className="category" key={category}>
            {category}
          </span>
        ))}
    </StyledCategories>
  );
};
export default PostingCategories;
