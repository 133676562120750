import Link from 'next/link';

import { Paragraph } from '@/components/core';
import { Button } from '@/components/core/Button';
import PostingContainer from '../PostingContainer';

import { Wrapper, Actions } from './styles';

const Cta = ({ content }) => {
  const { title, primaryHref, secondaryHref } = content;

  return (
    <PostingContainer>
      <Wrapper>
        <Paragraph className="text" markdownProps={{ elements: ['p', 'h5'] }}>
          {title}
        </Paragraph>
        <Actions>
          <Button variant="secondary" href={primaryHref.href} as={Link}>
            {primaryHref.label}
          </Button>
          {secondaryHref && (
            <Button variant="tertiary" href={secondaryHref.href} as={Link}>
              {secondaryHref.label}
            </Button>
          )}
        </Actions>
      </Wrapper>
    </PostingContainer>
  );
};

export default Cta;
