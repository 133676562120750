import styled from 'styled-components';

const PADDING = '24px';
const PADDING_LG = '32px';

export const StyledCardWrapper = styled.li`
  display: grid;
`;

export const StyledCardContainer = styled.div`
  border-radius: 16px;
  border: 1px solid ${(props) => props.theme.colors.grey['200']};
  background: ${(props) => props.theme.colors.white};
  display: grid;

  img {
    object-fit: contain;
  }

  ${(props) =>
    props.$isHighlight &&
    `
    background-color: ${props.theme.colors.primary.light};
  `}

  ${(props) =>
    props.$isClickable &&
    `
    cursor: pointer;
    transition: all 120ms ease-in-out;

    &:hover {
      background-color: ${props.theme.colors.primary.dark};
      border-color: ${props.theme.colors.primary.dark};
      color: ${props.theme.colors.white};
    }
  `}
`;

export const StyledCardContent = styled.div`
  display: grid;
  align-content: start;
  gap: 16px;
  padding: ${PADDING};

  @media (min-width: ${(props) => props.theme.breakpoints.min_lg}px) {
    padding: ${PADDING_LG};
  }
`;

export const StyledCardFooter = styled.footer`
  align-self: stretch;
  margin-top: auto;
  display: flex;
  align-items: center;
  gap: 16px;
  justify-content: space-between;
  padding: 16px ${PADDING};
  border-top: 1px solid ${(props) => props.theme.colors.grey['200']};
  transition: inherit;

  @media (min-width: ${(props) => props.theme.breakpoints.min_lg}px) {
    padding: 16px ${PADDING} 16px ${PADDING_LG};
  }

  a:hover & {
    border-color: ${(props) => props.theme.colors.white}33;
  }
`;
