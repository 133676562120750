import { memo } from 'react';

import { Container } from '@/lib/flexbox';
import Badge from '../Badge';

import { StyledSection, StyledTitle, StyledGrid, StyledFooter } from './styles';

const GridCards = ({ id, upperLabel, title, children, footer }) => (
  <StyledSection id={id} gradient="linear-gradient(180deg, #EEF4FD 0%, #FFF 100%)" spacerTop>
    <Container as="section">
      <Badge>{upperLabel}</Badge>
      <StyledTitle>{title}</StyledTitle>
      <StyledGrid>{children}</StyledGrid>
      {footer && <StyledFooter data-testid="grid-cards-footer">{footer}</StyledFooter>}
    </Container>
  </StyledSection>
);

export default memo(GridCards);
