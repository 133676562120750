import { Paragraph } from '@swordhealth/ui-corporate';
import styled from 'styled-components';

export const StyledCategories = styled(Paragraph)`
  color: ${({ theme }) => theme.colors.grey.default};
  text-transform: capitalize;

  .category {
    --category-spacing: 12px;
    --category-icon-size: 6px;
    align-items: center;
    display: inline-flex;
    margin-right: var(--category-spacing);

    &::after {
      background-color: ${({ theme }) => theme.colors.grey.light};
      content: '';
      display: inline-block;
      margin-left: var(--category-spacing);
      border-radius: ${({ theme }) => theme.borderRadius.full};
      height: var(--category-icon-size);
      width: var(--category-icon-size);
    }

    &:last-child {
      margin-right: 0;

      &::after {
        display: none;
      }
    }
  }
`;
