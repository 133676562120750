import { Section } from '@/components/core';
import { Container } from '@/lib/flexbox';
import styled from 'styled-components';

export const StyledContainer = styled(Container)`
  max-width: 906px;
`;

export const StyledSection = styled(Section)`
  &:last-child {
    padding-bottom: 0;
  }
`;
