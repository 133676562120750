import { memo } from 'react';

import ArrowRight from '@/public/assets/icons/arrow-right.svg';
import { Icon } from './styles';

const ArrowIndicator = ({ bgColor, color, ...rest }) => {
  return (
    <Icon data-testid="arrow-indicator-wrap" $color={color} $bgColor={bgColor} {...rest}>
      <ArrowRight aria-hidden="true" data-testid="arrow-indicator" />
    </Icon>
  );
};

export default memo(ArrowIndicator);
