import cmsAxios from '@/lib/cms-axios';
import leverApi from '@/lib/lever-dev';
import * as Sentry from '@sentry/nextjs';

export const slugifyDepartment = (name) => {
  if (!name) return null;

  return encodeURI(name.toLowerCase().replaceAll(' ', '-'));
};

export const unslugifyDepartment = (slug) => {
  if (!slug) return null;

  return decodeURI(slug).toLowerCase().replaceAll('-', ' ');
};

export async function getCareers() {
  return (
    await cmsAxios.get('/career', {
      params: {
        populate: [
          'seo',
          'seo.image',
          'joinUs',
          'joinUs.cards',
          'joinUs.spontaneousApplication',
          'joinUs.spontaneousApplication.footer',
          'joinUs.cta',
          'joinUs.cta.primaryHref',
          'joinUs.cta.secondaryHref',
          'mission',
          'mission.quotes',
          'mission.quotes.image',
          'investors',
          'investors.companies',
          'investors.companies.image',
          'values',
          'values.cards',
          'values.cards.image',
          'awards',
          'awards.awardsCard',
          'awards.awardsCard.image',
          'awards.awardsCard.imageHover',
          'header',
          'culture',
          'culture.cultures',
          'culture.cultures.image',
          'press',
          'press.pressCard',
          'press.pressCard.icon',
          'press.pressCard.logo',
          'press.pressCard.link',
          'press.pressCard.imageBackground',
          'news',
          'news.textLink',
          'news.articles',
          'news.articles.category',
          'news.articles.featuredImage',
          'news.articles.footnotes',
          'news.articles.footnotes.footnote',
          'video',
          'video.video',
          'video.previewVideo',
          'headerBanner',
          'headerBanner.logo',
        ],
      },
    })
  ).data.data.attributes;
}

export async function getCareersJoinUs() {
  return (
    await cmsAxios.get('/career', {
      params: {
        populate: [
          'seo.image',
          'joinUs.cards',
          'joinUs.spontaneousApplication',
          'joinUs.openJobs',
          'joinUs.cta',
          'joinUs.cta.primaryHref',
          'joinUs.cta.secondaryHref',
        ],
      },
    })
  ).data.data.attributes;
}

export async function getAllPostings() {
  try {
    const response = await leverApi.get('/postings', {
      params: {
        state: 'published',
        distributionChannels: 'public',
        confidentiality: 'non-confidential',
      },
    });

    if (!response?.data?.data) {
      throw new Error('Undefined listings data');
    }

    const data = response?.data?.data || [];

    let departments = [];

    for (const posting of data) {
      const department = posting.categories.department;
      const index = departments.findIndex((i) => i.name === department);

      if (index <= -1 && department) {
        departments.push({ name: department, quantify: 1 });
      } else {
        departments[index].quantify = departments[index].quantify + 1;
      }
    }

    return {
      departments: departments.sort((a, b) => a.name.localeCompare(b.name)),
    };
  } catch (err) {
    Sentry.captureException(new Error('Lever Request Error', { error: err }));

    throw new Error(`Failed call Lever Request: ${err}`);
  }
}
export async function getAllPostingsByDepartment(slug) {
  try {
    const departmentName = unslugifyDepartment(slug);
    const response = await leverApi.get(`/postings`, {
      params: {
        state: 'published',
        distributionChannels: 'public',
        confidentiality: 'non-confidential',
      },
    });
    if (!response?.data?.data) {
      throw new Error('Undefined listings data');
    }

    const result = response.data.data.filter(
      (item) => item.categories.department.toLowerCase() === departmentName,
    );

    const data = result || [];

    let departments = [];

    for (const posting of data) {
      const team = posting.categories.team;
      const index = departments.findIndex((i) => i.name === team);

      // Hide non public postings
      if (!posting.distributionChannels?.includes('public')) {
        continue;
      }

      if (index <= -1 && team) {
        departments.push({
          name: team,
          quantify: 1,
          list: [posting],
        });
      } else {
        departments[index].quantify = departments[index].quantify + 1;
        departments[index].list = [...departments[index].list, posting];
      }
    }

    return {
      title: result?.[0]?.categories.department || departmentName,
      departments: departments.sort((a, b) => a.name.localeCompare(b.name)),
    };
  } catch (err) {
    Sentry.captureException(new Error('Lever Request Error', { error: err }));

    throw new Error(`Failed call Lever Request: ${err}`);
  }
}

export async function getLeverPosting(id) {
  try {
    if (!id) return null;

    const response = await leverApi.get(`/postings/${id}?distribution=external`);
    const data = response?.data?.data;

    if (!data) {
      // return null to prevent whole page from erroring out
      return null;
    }

    // Hide non public postings
    if (!data.distributionChannels?.includes('public')) {
      return null;
    }

    return data;
  } catch (err) {
    Sentry.captureException(new Error('Lever Get Form Error', { error: err }));

    throw new Error(`Failed Lever Request: ${err}`);
  }
}

export async function getLeverPostingForm(id) {
  try {
    if (!id) return null;

    const response = await leverApi.get(`/postings/${id}/apply?distribution=external`);
    const data = response?.data?.data;

    if (!data) {
      // return null to prevent whole page from erroring out
      return null;
    }

    return data;
  } catch (err) {
    Sentry.captureException(new Error('Lever Get Form Error', { error: err }));

    throw new Error(`Failed Lever Request: ${err}`);
  }
}

export async function getLeverPostingDiversitySurvey(id, countryCode) {
  try {
    if (!id || !countryCode) return null;

    const response = await leverApi.get(`/surveys/diversity/${id}?country_code=${countryCode}`);
    const data = response?.data?.data;

    if (!data) {
      // return null to prevent whole page from erroring out
      return null;
    }

    return data;
  } catch (err) {
    Sentry.captureException(new Error('Lever Get Diversity Form Error', { error: err }));

    throw new Error(`Failed Lever Request: ${err}`);
  }
}

export function mapApplicationPayload(values, resumeData, diversitySurvey) {
  if (!values || !resumeData) return null;

  const eeoResponses = Object.keys(values.eeoResponses || {}).reduce((acc, key) => {
    const value = values.eeoResponses[key];

    if (value) {
      acc[key] = value;
    }

    return acc;
  }, {});
  const diversityResponses = Object.keys(values?.diversitySurvey?.responses || {}).reduce(
    (acc, key) => {
      const value = values.diversitySurvey.responses[key];

      if (value) {
        const question = diversitySurvey?.fields?.find((field) => field.id === key);
        acc.push({
          questionId: key,
          answer: value,
          questionText: question?.text,
          questionType: question?.type,
        });
      }

      return acc;
    },
    [],
  );

  // format data to match Lever API
  // https://hire.lever.co/developer/documentation#apply-to-a-posting
  return {
    id: values.id,
    customQuestions: Object.keys(values.customQuestions || {}).reduce((acc, key) => {
      acc.push({
        id: key,
        fields: values.customQuestions[key].map((value) => ({
          value: value ? value : null,
        })),
      });

      return acc;
    }, []),
    personalInformation: Object.keys(values.personalInformation || {}).reduce((acc, key) => {
      const value = values.personalInformation[key];
      const isResume = key === 'resume';

      if (value || isResume) {
        acc.push({
          name: key,
          value: isResume ? resumeData.uri : value,
        });
      }

      return acc;
    }, []),
    urls: Object.keys(values.urls || {}).reduce((acc, key) => {
      acc.push({
        name: key,
        value: values.urls?.[key] || '',
      });

      return acc;
    }, []),
    ...(Object.keys(eeoResponses).length > 0 ? { eeoResponses } : {}),
    ...(Object.keys(diversityResponses).length > 0
      ? {
          diversitySurvey: {
            ...values.diversitySurvey,
            surveyId: diversitySurvey.id,
            responses: diversityResponses,
          },
        }
      : {}),
  };
}

export async function applyLeverPosting(postingId, applicationData) {
  try {
    if (!postingId || !applicationData) {
      throw new Error('Missing application data');
    }

    const response = await leverApi.post(
      `/postings/${postingId}/apply?send_confirmation_email=true`,
      applicationData,
      {
        timeout: 1000 * 60, // Wait for 60 seconds
      },
    );
    const data = response?.data?.data;

    if (!data) {
      throw new Error('Undefined response');
    }

    return data;
  } catch (err) {
    Sentry.captureException(new Error('Lever Application Error', { error: err }));

    throw new Error(`Failed Lever Request: ${JSON.stringify(err)}`);
  }
}

export async function uploadLeverFile(req) {
  try {
    if (!req) {
      throw new Error('Missing data');
    }

    const response = await leverApi.post('/uploads', req, {
      headers: {
        'Content-Type': req.headers['content-type'], // which is multipart/form-data with boundary included
      },
    });

    const data = response?.data?.data;

    if (!data) {
      throw new Error('Undefined file response');
    }

    return data;
  } catch (err) {
    Sentry.captureException(new Error('Lever Upload Error', { error: err }));

    throw new Error(`Failed Lever Request: ${err}`);
  }
}

export const getLogo = async (url) => {
  try {
    const response = await fetch('/api/careers/press-svg', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ fileUrl: url }),
    })
      .then((res) => res.text())
      .then((svg) => svg);

    return `${response}`;
  } catch (err) {
    console.error(err);
  }
};
