import React, { memo } from 'react';
import Image from 'next/image';
import Link from 'next/link';
import { getImgData } from '@/utils/images';
import useUrlAttributes from '@/utils/useUrlAttributes';

import { Paragraph, Title } from '@/components/core';
import {
  StyledCardContainer,
  StyledCardContent,
  StyledCardFooter,
  StyledCardWrapper,
} from './styles.js';
import ArrowIndicator from '../ArrowIndicator/ArrowIndicator.js';

const Card = ({
  href,
  title,
  titleSize = '6',
  description,
  image,
  footer = null,
  isHighlight = false,
  containerProps = {},
  ...rest
}) => {
  const imageData = getImgData(image);
  const urlAttributes = useUrlAttributes(href);

  return (
    <StyledCardWrapper {...rest}>
      <StyledCardContainer
        data-testid="card-container"
        $isHighlight={isHighlight}
        {...containerProps}
        $isClickable
        {...(href ? { as: Link, href, ...urlAttributes, $isClickable: true } : {})}
      >
        <StyledCardContent>
          {imageData && (
            <Image
              src={imageData.url}
              alt={imageData.alternativeText || ''}
              width={56}
              height={56}
            />
          )}
          <Title size={titleSize} as="p">
            {title}
          </Title>
          {description && <Paragraph>{description}</Paragraph>}
        </StyledCardContent>
        {footer && (
          <StyledCardFooter data-testid="card-footer">
            {footer}
            <ArrowIndicator bgColor={isHighlight ? 'white' : null} />
          </StyledCardFooter>
        )}
      </StyledCardContainer>
    </StyledCardWrapper>
  );
};

export default memo(Card);
