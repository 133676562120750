import styled from 'styled-components';
import { Section as UnstyledSection } from '@/components/core';
import { BASE_CONF } from '@/lib/flexbox/config';

const Section = styled(UnstyledSection).attrs({ forwardedAs: 'div', spacerTop: true })`
  --line-offset: ${BASE_CONF.outerMargin}px;

  background-color: ${(props) => props.theme.colors.neutral.default};
  position: relative;

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    --line-offset: ${BASE_CONF.mobileOuterMargin}px;
  }

  &:after {
    content: '';
    display: block;
    width: calc(100vw - var(--line-offset) * 2);
    height: 1px;
    max-width: 1264px;
    background-color: ${(props) => props.theme.colors.grey.dark};
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    opacity: 0.15;
  }
`;

export default Section;
