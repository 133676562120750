import { useState } from 'react';
import { mapApplicationPayload } from '@/services/careers';
import countriesList from '@/utils/countries-list';
import { fileFromFileData } from '@/components/core/forms/input/FileUpload';
import { Form, Field } from '@/components/core';
import HeaderJoinUs from '../HeaderJoinUs';
import Section from '@/components/shared/Legal/Section';

import { trackCareersApplied } from '@/utils/track';
import { identifyJob } from '@/utils/identify';

import { StyledContainer, StyledFieldset, StyledLegend, StyledLegendInfo } from './styles';

/**
 * @param {Array} fields
 * @returns {Object}
 * @example
 * mapLeverDefaultFields([
 * {
 * name: 'name',
 * text: 'First Name',
 * required: true,
 * type: 'text',
 * },
 * {
 * name: 'email',
 * text: 'Email',
 * required: true,
 * type: 'text',
 * },
 * ]);
 * // returns
 * {
 * name: '',
 * email: '',
 * }
 * */
function mapLeverDefaultFields(fields) {
  return fields.reduce((acc, field) => {
    acc[field.name] = '';
    return acc;
  }, {});
}

/**
 * @param {Array} fields
 * @returns {Object}
 * @example
 * mapLeverCustomFields([
 * {
 * id: '123',
 * fields: [
 * {
 * id: '456',
 * text: 'First Name',
 * required: true,
 * type: 'text',
 * },
 * {
 * id: '789',
 * text: 'Email',
 * required: true,
 * type: 'text',
 * },
 * ],
 * },
 * ]);
 *
 * // returns
 * {
 * 123: {
 * 456: '',
 * 789: '',
 * },
 * }
 
 * */
function mapLeverCustomFields(groups) {
  return groups.reduce((acc, group) => {
    acc[group.id] = [];

    group.fields.forEach((field) => {
      const isArray = field.type === 'multiple-select';

      acc[group.id].push(isArray ? [] : '');
    });

    return acc;
  }, {});
}

function mapLeverEooFields(fields) {
  if (!fields) return {};

  return Object.keys(fields).reduce((acc, key) => {
    acc[key] = '';

    return acc;
  }, {});
}

function mapLeverField(field, groupKey) {
  const isArray = field.index !== undefined;
  const fieldIndex = isArray ? `.${field.index}` : null;
  const fieldName = `.${field.name || field.id}`;
  const isEmail = field.name === 'email';

  return {
    name: `${groupKey}${fieldIndex || fieldName}`,
    label: `${field.text}${field.type === 'url' ? ' URL' : ''}`,
    required: field.required,
    type: isEmail ? 'email' : field.type,
    validation: isEmail ? 'email' : field.validation,
    ...(field.options
      ? {
          options: field.options.map((option) => ({ value: option.text, label: option.text })),
          minimal: true,
          fullWidth: true,
        }
      : {}),

    ...(field.name === 'resume'
      ? { accept: ['.pdf'], maxSize: 5, helperMessage: 'Upload a PDF file with maximum of 5MB' }
      : {}),
  };
}

const ApplicationForm = ({
  backButton,
  formData,
  formTitle,
  formDescription,
  formError,
  formSuccess,
  posting,
}) => {
  const [
    { data: diversitySurvey, country: diversityCountry, loading: diversitySurveyLoading },
    setDiversitySurvey,
  ] = useState({
    data: null,
    country: null,
    loading: false,
  });

  if (!formData) return null;

  const aditionalInfoField = formData.personalInformation?.find(
    ({ name }) => name === 'additionalInformation',
  );

  return (
    <Section>
      <StyledContainer>
        <Form
          canSubmit={!diversitySurveyLoading}
          validateOnBlur={false}
          initialValues={{
            id: formData.id,
            eeoResponses: mapLeverEooFields(formData.eeoQuestions),
            personalInformation: mapLeverDefaultFields(formData.personalInformation),
            customQuestions: mapLeverCustomFields(formData.customQuestions),
            urls: mapLeverDefaultFields(formData.urls),
            diversitySurvey: {},
            consent: true,
          }}
          onSubmit={async (values) => {
            // lever requires file to be sent as multipart/form-data
            const formData = new FormData();
            const resume = await fileFromFileData(values.personalInformation.resume);
            formData.append('file', resume);

            const resumeResponse = await fetch('/api/careers/posting/upload-resume', {
              method: 'POST',
              body: formData,
            });
            const resumeData = await resumeResponse.json();

            if (!resumeData.uri) {
              throw new Error('Failed to upload resume');
            }

            const applicationSubmit = await fetch('/api/careers/posting/apply', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify(mapApplicationPayload(values, resumeData, diversitySurvey)),
            });

            if (!applicationSubmit.ok) {
              throw new Error('Failed to submit application');
            }

            trackCareersApplied({ title: formData.text, id: values.id });

            identifyJob({
              firstName: values.personalInformation.name,
              lastName: values.personalInformation.lastName,
              email: values.personalInformation.email,
              company: values.personalInformation.company,
              phone: values.personalInformation.phone,
              formSlug: values.id,
            });
          }}
          initialError={!formData?.id}
          formError={formError}
          formSuccess={formSuccess}
          submitLabel="Submit application"
        >
          <HeaderJoinUs
            posting={posting}
            title={formTitle}
            description={formDescription}
            backLink={backButton}
          />
          {formData.personalInformation && (
            <StyledFieldset>
              <StyledLegend>Submit your application</StyledLegend>
              {formData.personalInformation
                ?.filter(({ name }) => name !== aditionalInfoField?.name)
                .map((field) => (
                  <Field
                    key={field.name}
                    {...mapLeverField(field, 'personalInformation')}
                    asPlaceholder
                  />
                ))}
            </StyledFieldset>
          )}
          {formData.urls && (
            <StyledFieldset>
              <StyledLegend>Links</StyledLegend>
              {formData.urls.map((field) => (
                <Field
                  key={field.name}
                  {...mapLeverField({ ...field, type: 'url' }, 'urls')}
                  type="url"
                  validation="url"
                  asPlaceholder
                />
              ))}
            </StyledFieldset>
          )}
          {formData.customQuestions?.map((group) => (
            <StyledFieldset key={group.id}>
              <StyledLegend>{group.text}</StyledLegend>
              {group.fields.map((field, index) => (
                <Field
                  key={field.id}
                  {...mapLeverField({ ...field, index }, `customQuestions.${group.id}`)}
                />
              ))}
            </StyledFieldset>
          ))}
          {formData.eeoQuestions && (
            <StyledFieldset>
              <StyledLegend>Equal employment opportunity information</StyledLegend>
              {Object.keys(formData.eeoQuestions).map((name) => (
                <Field
                  key={name}
                  helperMessage="Completion is voluntary and will not subject you to adverse treatment"
                  {...mapLeverField({ ...formData.eeoQuestions[name], name }, 'eeoResponses')}
                />
              ))}
            </StyledFieldset>
          )}
          {aditionalInfoField && (
            <StyledFieldset>
              <StyledLegend>{aditionalInfoField.text}</StyledLegend>
              <Field
                {...mapLeverField(aditionalInfoField, 'personalInformation')}
                label="Add a cover letter or anything else you want to share."
              />
            </StyledFieldset>
          )}
          <StyledFieldset>
            <StyledLegend>Demographic Survey</StyledLegend>
            <Field
              type="dropdown"
              loading={diversitySurveyLoading}
              label="What is your location?"
              name="diversitySurvey.candidateSelectedLocation"
              options={countriesList.map(({ code, name }) => ({ value: code, label: name }))}
              onChange={async (value) => {
                try {
                  if (diversityCountry === value) {
                    return;
                  }

                  setDiversitySurvey({ country: value, data: null, loading: true });

                  const survey = await fetch(
                    `/api/careers/posting/survey?id=${formData.id}&country=${value}`,
                  );
                  const surveysData = await survey.json();

                  setDiversitySurvey((prev) => ({
                    ...prev,
                    data: surveysData?.[0] || null,
                    loading: false,
                  }));
                } catch (error) {
                  setDiversitySurvey((prev) => ({ ...prev, data: null, loading: false }));
                }
              }}
              minimal
              fullWidth
            />
          </StyledFieldset>
          {diversitySurvey?.fields?.length > 0 && (
            <StyledFieldset>
              <StyledLegend>
                {diversitySurvey.text}
                <StyledLegendInfo>{diversitySurvey.instructions}</StyledLegendInfo>
              </StyledLegend>
              {diversitySurvey.fields.map((field) => (
                <Field key={field.id} {...mapLeverField(field, 'diversitySurvey.responses')} />
              ))}
            </StyledFieldset>
          )}
        </Form>
      </StyledContainer>
    </Section>
  );
};

export default ApplicationForm;
